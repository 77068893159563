import React from "react";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
  return (
    <div>
      <h1 className="termHeading">Terms and Conditions</h1>

      <section className="termSection">
        <h2>1. Introduction</h2>
        <p>
          Medidoc is a website and app that allows patients and health
          practitioners to engage in telehealth consultations by video call,
          telephone call, and other mediums. These terms of service (Terms)
          govern your use of the services (together our Platform) offered by
          Medidoc ("we", "us", or "our"). By accessing and using our Services,
          you agree to be bound by these Terms together with our privacy policy
          (Privacy Policy). If you do not agree to these Terms, you may not
          access or use our Services.
        </p>
      </section>

      <section className="termSection">
        <h2>2. Eligibility</h2>
        <ol>
          <li>
            You must be at least 18 years old and either the patient or their
            legal guardian and residing in Australia to use our Services.
          </li>
          <li>You must be in Australia at the time of using the Services.</li>
          <li>
            You are solely responsible for ensuring the accuracy and
            completeness of all information you provide.
          </li>
        </ol>
      </section>

      <section className="termSection">
        <h2>3. Medical Services</h2>
        <ol>
          <li>
            Medidoc does not provide medical advice and does not warrant or
            represent that any particular treatment is safe, appropriate, or
            effective. Medidoc health practitioners are responsible for all
            medical services provided through the Medidoc Service.
          </li>
          <li>
            Medidoc is not a substitute for in-person consultations with your
            regular doctor, and patients are encouraged to attend for ongoing
            medical care from their regular GP or primary care physician.
          </li>
          <li>
            As a patient (or guardian of a patient, if applicable), you
            acknowledge and agree to answer all questions accurately,
            completely, and honestly, and agree to see your regular
            doctor/GP/physician for follow-up care where necessary.
          </li>
        </ol>
      </section>

      <section className="termSection">
        <h2>4. Services</h2>
        <ol>
          <li>
            We offer telehealth consultations with registered and qualified
            Healthcare Practitioners in Australia.
          </li>
          <li>
            Services may include video conferencing, phone consultations, and
            other approved technologies.
          </li>
          <li>
            Prescriptions for medication may be administered subject to clinical
            appropriateness.
          </li>
          <li>
            We may offer referrals to specialists, medical letters, or medical
            certificates, subject to clinical appropriateness.
          </li>
          <li>
            Our services are not a substitute for in-person medical care. If you
            require urgent medical attention, please call emergency services
            immediately or attend the closest hospital Emergency Department.
          </li>
          <li>
            Our Healthcare Practitioners are not able to prescribe certain types
            of medications via telehealth, including but not limited to:
            <ul>
              <li>
                <strong>Schedule 8 drugs</strong>, such as{" "}
                <strong>Oxycodone</strong> (OxyContin),{" "}
                <strong>Morphine</strong>, <strong>Fentanyl</strong>, and{" "}
                <strong>Methadone</strong>, which are controlled substances with
                a high potential for abuse or addiction.
              </li>
              <li>
                Medications requiring in-person evaluation or physical
                examination, such as <strong>Benzodiazepines</strong> (e.g.,{" "}
                <strong>Diazepam</strong>, <strong>Alprazolam</strong>) and
                certain <strong>antipsychotics</strong> (e.g.,{" "}
                <strong>Clozapine</strong>).
              </li>
              <li>
                Medications subject to special restrictions, including those
                requiring regular monitoring (e.g., anticoagulants, certain
                cancer treatments).
              </li>
            </ul>
          </li>
          <li>
            All prescriptions are subject to the clinical judgment of the
            Practitioner, and prescriptions will only be provided where it is
            deemed clinically appropriate based on the information provided
            during the telehealth consultation.
          </li>
        </ol>
      </section>

      <section className="termSection">
        <h2>5. Use of the Platform</h2>
        <ol>
          <li><strong>Symptom Limitations:</strong> The patient is not experiencing severe or unusual symptoms (e.g. including but not limited to sudden vision changes, severe headache, unexplained pain/nausea, fainting, bleeding).</li>
          <li><strong>Accident Exclusion:</strong> The need for the Consultation is not related to any accident or injury.</li>
          <li><strong>Medical Complexity:</strong> The patient does not have multiple/serious chronic conditions or take numerous prescription medications.</li>
          <li><strong>Controlled Substances:</strong> The patient does not require 'schedule 8' (narcotics) or other medications with abuse/addiction potential.</li>
          <li><strong>Accurate Information:</strong> You have completed the medical questions form truthfully and completely, without omitting any relevant information.</li>
          <li><strong>Advice Disclaimer:</strong> We do not guarantee the quality of medical advice provided by Healthcare Practitioners.</li>
          <li><strong>Qualifications Disclaimer:</strong> We do not guarantee Healthcare Practitioner specialisations and are not responsible for the conduct or advice given during a Consultation.</li>
          <li><strong>Hearing Assistance:</strong> If you or the patient are hard of hearing, a hearing device will be used during the Consultation.</li>
          <li><strong>Consultation Fees:</strong> You are responsible for the Consultation costs as per these Terms.</li>
          <li><strong>Follow-up Responsibility:</strong> If the Healthcare Practitioner suggests further treatment/examination, you will pursue this promptly. We and the Healthcare Practitioner are not responsible for patient follow-up.</li>
          <li><strong>Medication Responsibility:</strong> Prescribed medication is exclusively for the Patient's personal use.</li>
          <li><strong>Account Security:</strong> You agree not to share your Platform login information and will protect your account access.</li>
          <li><strong>Cancellation Rights:</strong> We reserve the right to cancel your registration at our discretion.</li>
          <li><strong>After-care Notification:</strong> You will immediately report any noticeable medication/treatment side effects experienced by the patient.</li>
          <li><strong>Good Faith and Legal Use:</strong> You will only use the Platform as intended and in compliance with Australian law.</li>
          <li><strong>Privacy Adherence:</strong> You will handle personal information in accordance with the Privacy Act 1988 (Cth) and relevant privacy laws.</li>
          <li><strong>Platform Safety:</strong> You will not upload or publish any malicious content (viruses, trojans, etc.) that could harm the Platform or its infrastructure.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>6. Practitioners</h2>
        <ol>
          <li>All our practitioners are independent and responsible for their clinical decisions and diagnoses.</li>
          <li>We do not guarantee the outcomes of any consultations or treatments provided by our practitioners.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>7. Fees and Payment</h2>
        <ol>
          <li>Our fees are displayed on our website and may vary depending on the service provided.</li>
          <li>All fees and charges associated with a consultation are to be paid at the time of booking to confirm the appointment using the credit card provided and you agree that we are authorised to immediately process the payment at the time of booking.</li>
          <li>You are responsible for paying all fees associated with your consultation.</li>
          <li>We accept various payment methods, which are stated on our website.</li>
          <li>By using the platform, you agree to the payment method you employ to be charged accordingly for the consultation fee as specified to you at the time of your booking.</li>
          <li>Refunds are at the sole discretion of the Practitioner. If no consultation has taken place then we will not charge you. However refunds for consultations already conducted with the Practitioner generally are not refundable unless authorised by the Practitioner themselves.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>8. Privacy and Confidentiality</h2>
        <ol>
          <li>We are committed to protecting your privacy and confidentiality and comply with all relevant Australian privacy laws and regulations.</li>
          <li>We will only use your personal information for the purposes of providing you with our services.</li>
          <li>You have the right to access and update your personal information.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>9. Security</h2>
        <ol>
          <li>We take reasonable steps to protect your personal information from unauthorised access, use, disclosure, alteration, or destruction.</li>
          <li>You are responsible for maintaining the security of your own devices and accounts used to access our services.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>10. Liability</h2>
        <ol>
          <li>You acknowledge and agree that we are not liable for any medical care you or a patient receives, nor for any medical advice provided by a Healthcare Practitioner (including documentation and medication). We are not liable for any damages (direct, indirect, special, punitive, incidental, exemplary, contractual, or consequential) resulting from your use of the Platform. To the fullest extent allowed by law, we are not liable for, and you agree to indemnify us (including related entities, officers, employees, and consultants) against all claims, actions, proceedings, losses, liabilities, and expenses (including legal expenses) arising from:
            <ol type="a">
              <li>All treatment, advice, or information provided during a consultation or any other Healthcare Practitioner services provided to a patient (including medications and suitability of services).</li>
              <li>Any personal injury, accident, death, alleged or actual malpractice, or negligence by a Healthcare Practitioner.</li>
              <li>Failure to answer questions truthfully or provide information requested by us or a Healthcare Practitioner.</li>
              <li>Your use of the Services offered by the Platform.</li>
              <li>We are not liable for any loss or damage you suffer as a result of using our Services, except as required by law.</li>
              <li>We are not liable for the clinical decisions or actions of our practitioners.</li>
            </ol>
          </li>
        </ol>
      </section>

      <section className="termSection">
        <h2>11. Termination</h2>
        <ol>
          <li>We may terminate your access to our services at any time, for any reason.</li>
          <li>You may terminate your use of our services at any time.</li>
        </ol>
      </section>

      <section className="termSection">
        <h2>12. Dispute Resolution</h2>

        <ol>
          <li>Any disputes arising out of these Terms will be governed by the laws of Victoria. Each party irrevocably submits to the non-exclusive jurisdiction of the courts of Victoria and courts competent to hear appeals from those courts.</li>
        </ol>
       
      </section>

      <section className="termSection">
        <h2>13. Amendments</h2>
        <ol>
          <li>We may amend these Terms at any time.</li>
          <li>Your continued use of our services after any amendments constitutes your acceptance of the amended Terms.</li>
        </ol>
      </section>
    </div>
  );
};

export default TermsAndConditions;
