import React from "react";
import style from "./diseases.module.scss";
import diseaseData from "./DiseasesData";
const Diseases = () => {
  return (
    <div className={style.diseasesContainer}>
      <div className={style.diseases}>
        <h1>Find doctors who can be consulted for these conditions</h1>
        <div className={style.diseaseCards}>
          {diseaseData.map((el) => {
            return (
              <div className={style.diseaseArray} key={el?.index}>
                <img src={el?.img} className={style.diseaseImg} />
                <h2>{el?.heading}</h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Diseases;
