import React from "react";
import PrimaryButton from "../../Components/UI/PrimaryButton/PrimaryButton";
import ScrollCarousel from "scroll-carousel-react";
import "./Landing.scss";

import {
  Asthma,
  BloodTest,
  Bronchitis,
  Diarrhea,
  Fever,
  GORD,
  HeartBurn,
  ImagingRequest,
  MentalHealth,
  ReferralLetters,
  Reflux,
  RepeatMedication,
  STI,
  SkinProblem,
  UTI,
  Vomiting,
  Xray,
  anxiety,
  arrow,
  clock,
  cough,
  doctor,
  fever,
  lightIcon,
  main,
  mainImg,
  mainImgOne,
  medicalCertificate,
  migraine,
  person,
  persons,
  soreThroat,
} from "../../Assets";
import { ReactSVG } from "react-svg";
import { Link, useNavigate } from "react-router-dom";
import Testimonials from "../../Components/Testimonials/Testimonial";
import Accordion from "../../Components/FAQ/Accordion";
import About from "../about/About";
import Contact from "../contact/Contact";
const megaButton = [
  {
    icon: person,
    heading: "Individual Consultation",
    description: "For myself",
  },
  {
    icon: persons,
    heading: "Kids Consultation",
    description: "For my child",
  },
  {
    icon: persons,
    heading: "Couple Consultation",
    description: "For my spouse",
  },
];
const diseasesScroll1 = [
  {
    img: UTI,
    heading: "Urinary Tract Infection",
  },
  {
    img: soreThroat,
    heading: "Sore Throat",
  },
  {
    img: Fever,
    heading: "Fever",
  },
  {
    img: medicalCertificate,
    heading: "Medical certificates",
  },
  {
    img: ReferralLetters,
    heading: "Referral letters",
  },
  {
    img: RepeatMedication,
    heading: "Repeat medication",
  },
  {
    img: Xray,
    heading: "X-ray Requests",
  },
  {
    img: ImagingRequest,
    heading: "Imaging Requests",
  },
  {
    img: BloodTest,
    heading: "Blood Tests",
  },
  {
    img: STI,
    heading: "Sexual Health Test",
  },
  {
    img: cough,
    heading: "Cough",
  },
  {
    img: Vomiting,
    heading: "Vomiting",
  },
  {
    img: Diarrhea,
    heading: "Diarrhea",
  },
  {
    img: Reflux,
    heading: "Reflux (GORD)",
  },
  {
    img: HeartBurn,
    heading: "Heart Burn",
  },
  {
    img: MentalHealth,
    heading: "Mental Health Discussion",
  },
  {
    img: SkinProblem,
    heading: "Skin Problems",
  },
  {
    img: Asthma,
    heading: "Asthma",
  },
  {
    img: Bronchitis,
    heading: "Bronchitis",
  },
];

const consultSteps = [
  {
    num: 1,
    heading: "Book a consultation online",
    description: "Skip the waiting room and save time",
  },
  {
    num: 2,
    heading: "Speak to a Doctor",
    description:
      "Discuss your symptoms or medical issues in a secure, confidential manner via phone call or video call.",
  },
  {
    num: 3,
    heading: "Finish",
    description:
      "Get prescriptions, letters or advice depending on the outcome of your assessment",
  },
];
const Landing = ({ setIsNav }) => {
  // const handleConsultClick = () => {
  //   window.location.href = "/booking";
  // };

  const navigate = useNavigate();

  const handleNextPageClick = () => {
    navigate("/diseases");
  };

  return (
    <div className="landing-container" onClick={() => setIsNav(false)}>
      <div className="main">
        <h5>
          Are you having <span> fever? </span>
        </h5>
        <div className="heading">
          <h1>
            Consult with Australian registered doctors by telephone or video
            call
            <ReactSVG src={lightIcon} />
          </h1>
          <p>
            <strong>Skip the waiting queues!</strong> Speak to a Doctor from the
            comfort of your home
          </p>
          <PrimaryButton
            className="button"
            onClick={() => navigate("/booking")}
          >
            Consult Now
          </PrimaryButton>
        </div>

        <img src={mainImg} alt="img-main" className="main-img" />
      </div>
      <div className="button-heading">
        <h6>Service we could provide</h6>
        <div className="mega-button">
          {megaButton.map((el) => {
            return (
              <div className="button-array">
                <div className="icon">
                  <ReactSVG src={el.icon} />
                </div>
                <h2>{el.heading}</h2>
                <div className="arrow-button">
                  <span onClick={() => navigate("/booking")}>
                    {el.description}
                  </span>
                  <ReactSVG src={arrow} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="video-session">
        <ReactSVG src={doctor} className="doctor" />
        <div className="description">
          <h2>Affordable sessions with doctor</h2>
          <p>
            Experience accessible healthcare at its finest with our affordable
            online sessions with experienced doctors. We understand the
            importance of quality medical care without breaking the bank. Our
            platform offers you the opportunity to consult with skilled
            healthcare professionals from the comfort of your home, all at
            budget-friendly rates.
          </p>
          <div className="session-button" onClick={() => navigate("/booking")}>
            <div className="button-icon">
              <ReactSVG src={clock} />
              {/* <h3>Round the clock healthcare service</h3> */}
              <h3>Consult Now</h3>
            </div>
            <ReactSVG src={arrow} />
          </div>
        </div>
      </div>
      <div className="diseases">
        <h1>Examples of some conditions that Medidoc doctors can help with</h1>
        <div className="disease-cards">
          <ScrollCarousel
            autoplay
            autoplaySpeed={1}
            speed={2}
            onReady={() => console.log("I am ready")}
          >
            {diseasesScroll1.map((el, index) => {
              return (
                <div className="disease-array" key={el.index}>
                  <img src={el.img} className="diseaseImg" />
                  <h2>{el.heading}</h2>
                </div>
              );
            })}
          </ScrollCarousel>
        </div>
        <div className="more-button" onClick={handleNextPageClick}>
          <span>See all</span>
          <ReactSVG src={arrow} />
        </div>
      </div>
      <div id="consult-steps">
        <h1 id="navigate"> How it works?</h1>
        <p className="description">As easy as 1...2...3..</p>
        <div className="steps-cards">
          {consultSteps.map((el) => {
            return (
              <div className="steps-array">
                <span>{el.num}</span>
                <div className="headiang-des">
                  <h2>{el.heading}</h2>
                  <p>{el.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bottom-button">
        <h2>Ready to Consult right away?</h2>
        <a href="mailto:abc@abc.com">
          <PrimaryButton className="button">Get Started</PrimaryButton>
        </a>
      </div>
      <About />
      <Testimonials />
      <Accordion />
      <Contact />
    </div>
  );
};

export default Landing;
