import React from "react";

const DoctorProfile = () => {
  return (
    <>
      <iframe
        src="https://www.halaxy.com/profile/dr-luqmaan-malik/gp-general-practitioner/1252651"
        style={{ border: 0, width: "100%", height: "100vh" }}
      ></iframe>
    </>
  );
};

export default DoctorProfile;
