import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonial.css";
import TestimonialData from "./TestimonialData";
import { ReactSVG } from "react-svg";
import { arrow } from "../../Assets";
import { Rate } from "antd";

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? TestimonialData.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev === TestimonialData.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <div className="testmonialContainer">
      <h1 className="testimonialHeading">Testimonials</h1>
      <Carousel
        selectedItem={currentSlide}
        showThumbs={false}
        showStatus={false}
        showArrows={false} 
        infiniteLoop={true}
        autoPlay={true}
        interval={6000}
      >
        {TestimonialData.map((review) => (
          <div key={review.id}>
            <div className="myCarousel">
              <div>
                <Rate
                  disabled
                  defaultValue={review.rating}
                  className="rating"
                />
                <p>{review.clientReview}</p>
                <h3>{review.clientName}</h3>
              </div>
            </div>
          </div>
        ))}
      </Carousel>

      <div className="buttonContainer">
        <button
          type="button"
          className="carouselArrow prevArrow"
          onClick={handlePrev}
        >
          <ReactSVG src={arrow} className="prevArrowIcon" />
        </button>
        <button
          type="button"
          className="carouselArrow nextArrow"
          onClick={handleNext}
        >
          <ReactSVG src={arrow} />
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
