import React from "react";
import { Collapse } from "antd";
// Internal Imports
import style from "./Accordion.module.scss";
import AccordionData from "./AccordionData";

const { Panel } = Collapse;

const Accordion = () => {
  const callback = (key) => {
    console.log(key);
    // Handle key change if needed
  };

  return (
    <div
      id="accordionContainer"
      style={{
        width: "90%",
        marginInline: "auto",
        marginTop: "80px",
        marginBottom: "80px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "32px",
          color: "#25434d",
          marginBottom: "2%",
        }}
      >
        Frequently Asked Questions
      </h1>

      <Collapse
        defaultActiveKey={["1"]}
        onChange={callback}
        ghost
        expandIconPosition="end"
      >
        {AccordionData.map((item) => {
          return (
            <Panel
              header={item.header}
              key={item.id}
              style={{ borderBottom: "1px solid #D0DDE1", marginTop: "10px" }}
            >
              <p>{item.description}</p>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Accordion;
