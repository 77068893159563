const TestimonialData = [
  {
    id: 0,
    rating: 5,
    clientName: "Sarah",
    clientReview:
      "The process was incredibly quick and easy. I got my prescription within minutes and felt much better soon after. Highly recommend!",
  },
  {
    id: 1,
    rating: 4,
    clientName: "Luke",
    clientReview:
      "I was surprised by how easy it was to get a consultation. The doctor was thorough and resolved my issue efficiently. Great service!",
  },
  {
    id: 2,
    rating: 5,
    clientName: "Tenille",
    clientReview:
      "I appreciated the fast response and straightforward process. The outcome was just what I needed. I'll definitely use this service again.",
  },
];
export default TestimonialData;
