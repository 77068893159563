import React, { useEffect } from "react";
import "./Header.scss";
import { ReactSVG } from "react-svg";
import { headerLogo } from "../../Assets";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

function Header({ isNav, setIsNav }) {
  const navigate = useNavigate();

  // const handleConsultClick = () => {
  //   window.location.href = "/booking";
  // };
  const handleLoginClick = () => {
    // window.location.href = "https://www.halaxy.com/a/login";
    window.open("https://www.halaxy.com/a/login", "_blank");
  };

  return (
    <header className="header-container">
      <div className="logo-menu">
        <div onClick={() => navigate("/")}>
          <ReactSVG src={headerLogo} className="logo" />
        </div>
        <div
          className={isNav ? "change" : "menu"}
          onClick={() => setIsNav(!isNav)}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <div id="open" className={isNav ? "open" : "list-button"}>
        <ul>
          <Link offset={-80} to="navigate" smooth duration={500}>
            How it works
          </Link>
          <Link offset={-80} to="accordionContainer" smooth duration={500}>
            <li> FAQs</li>
          </Link>
          <Link offset={-70} to="aboutContainer" smooth duration={500}>
            <li>About us</li>
          </Link>
          <Link offset={-80} to="contactForm" smooth duration={500}>
            <li>Contact us</li>
          </Link>
        </ul>
        <PrimaryButton className="button" onClick={() => navigate("/booking")}>
          Consult Now
        </PrimaryButton>

        <PrimaryButton className="button" onClick={handleLoginClick}>
          Log in / Sign up
        </PrimaryButton>
      </div>
    </header>
  );
}

export default Header;
