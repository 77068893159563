 const AccordionData = [
    {
        id:"1",
        header:"What is Medidoc?",
        description:"Medidoc allows you to consult with Australian registered doctors from anywhere in Australia viatelephone call (using your mobile) or video call using your mobile or smart device."
    },
   
    {
        id:"2",
        header:"What can I do with Medidoc",
        description:"Our telehealth platform connects you to qualified Australian registered Doctors Similar to an in-person consultation, telehealth consultations require the doctor to understand your medical history and complaints, and decide on the most suitable treatment. If the doctor is unable to assist you through telehealth, they may recommend that you visit a face to face clinic or hospital."
    },
    {
        id:"3",
        header:"How much does it cost?",
        description:"The cost of a telehealth consultation is $54. Prices are subject to change."
    },
    {
        id:"4",
        header:"Who can use Medidoc?",
        description:"Anyone who is in Australia at the time of the consultation can use Medidoc services. Children or persons under 16 may require a parent/guardian to be in attendance during the consultations."
    },
    {
        id:"5",
        header:"Who are Medidoc doctors?",
        description:"All doctors offering services through Medidoc are verified Australian registered doctors holding registration with the Australian medical board and practicing to the highest standards."
    },
    {
        id:"6",
        header:"Can I get a Prescription or Repeat Prescription?",
        description:"Yes, if the doctor deems the request clinically appropriate.Some medications cannot be prescribed on Medidoc and these include Medications that areclassified as Schedule 8 (S8) controlled drugs, narcotics or opioids, and those that require close monitoring by a doctor. These would include Panadeine Forte, Mersyndol Forte, Benzodiazepines (Valium), Phentermine (Duromine), Semaglutide (Ozempic), Liraglutide (Saxenda), Modafinil, Armodafinil, Gabapentin, Quetiapine, Pregabalin (Lyrica), Tramadol (Tramal), Zolpidem (Stilnox), Zopiclone (Imovane), and Ivermectin (Stromectol)."
    },
    {
        id:"7",
        header:"Can I get a Medical Certificate, Pathology test/Blood Test Request, Imaging/X-ray Request or Specialist Referral?",
        description:"  Yes, upon consulting with the doctor and the request being deemed appropriate."
    },
    {
        id:"8",
        header:"Can I request a Medical Certificate for a past date?",
        description:"We can only issue medical certificates for present or future dates. However, the doctor may consider providing a certificate to cover a certain period of time in which you were medically  unwell."
    },
    {
        id:"9",
        header:"Are Medidoc Consultations Bulk Billed?",
        description:"Medidoc is unable to provide bulk billed consultations. However, Medidoc does provid competitively priced consultations that can be conveniently accessed."
    },
    {
        id:"10",
        header:"Can I use this service for a medical emergency?",
        description:"No. If you are experiencing a medical emergency such as (including but not limited to bloodloss, chest pain, breathlessness then emergency medical attention would be required. You may need to call 000 for emergency services."
    },
    
]

export default AccordionData;