import React from "react";
import "./about.scss";
import { ReactSVG } from "react-svg";
import {
  slideimg,
  // slideimg01,
  slideimg02,
  slideimg03,
  slideimg04,
  // slideimg05,
} from "../../Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const About = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      {/* header  */}
      <div id="aboutContainer">
      <h1 className="container-heading">About Us</h1>
        <div className="about-header">
       
          <div className="about-header-left">
           
          </div>
          <div className="about-header-right">
            <p>
              Our dedicated professionals are your beacon of hope during these
              critical times. They are trained, experienced, and ready to
              respond swiftly to provide the care and support you need. Whether
              it's a sudden injury, a medical crisis, or any other emergency,
              our Urgent Health Guardians are there to ensure you receive
              immediate attention and assistance.
            </p>
          </div>
        </div>
      </div>

      {/* slider  */}

      <div className="slides">
        <div className="slider">
          <Slider {...settings}>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
          </Slider>
        </div>
      </div>

      {/* Commitment and Healthier lives */}

      <div className="container">
        <div className="commitment_and_healthier_lives">
          <div className="commitment_and_healthier_lives_left">
            <h2>
              Our Commitment to Care defines our culture 
            </h2>
            <p>
              At Medidoc, our mission is deeply rooted in providing
              compassionate, comprehensive healthcare to every individual we
              serve. With a dedicated team of experienced professionals, we are
              committed to fostering a healing environment that prioritizes your
              well-being. Our holistic approach blends cutting-edge medical
              expertise with a personalized touch, ensuring that each patient
              receives the highest quality care tailored to their unique needs.
              By embracing innovation and empathy, we strive to be a trusted
              partner in your journey towards optimal health and vitality.
            </p>
          </div>
          <div className="commitment_and_healthier_lives_right">
            <h2>Empowering Healthier Lives, empowering the society</h2>
            <p>
              Welcome to Medidoc, where empowering healthier lives is at the
              core of everything we do. We believe that healthcare extends
              beyond treating symptoms—it's about fostering wellness and
              empowering individuals to lead fulfilling lives. Our
              multidisciplinary team of specialists works collaboratively,
              drawing from diverse expertise to offer integrated care that
              addresses both physical and emotional well-being. Through a
              patient-centered approach, we aim to create a supportive,
              inclusive environment where you feel empowered and encouraged on
              your path to better health.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
