import {
  Asthma,
  BloodTest,
  Bronchitis,
  Diarrhea,
  Fever,
  HeartBurn,
  ImagingRequest,
  MentalHealth,
  ReferralLetters,
  Reflux,
  RepeatMedication,
  STI,
  SkinProblem,
  UTI,
  Vomiting,
  Xray,
  cough,
  medicalCertificate,
  soreThroat,
} from "../../Assets";

const diseaseData = [
  {
    img: UTI,
    heading: "Urinary Tract Infection",
  },
  {
    img: soreThroat,
    heading: "Sore Throat",
  },
  {
    img: Fever,
    heading: "Fever",
  },
  {
    img: medicalCertificate,
    heading: "Medical certificates",
  },
  {
    img: ReferralLetters,
    heading: "Referral letters",
  },
  {
    img: RepeatMedication,
    heading: "Repeat medication",
  },
  {
    img: Xray,
    heading: "X-ray Requests",
  },
  {
    img: ImagingRequest,
    heading: "Imaging Requests",
  },
  {
    img: BloodTest,
    heading: "Blood Tests",
  },
  {
    img: STI,
    heading: "Sexual Health Test",
  },
  {
    img: cough,
    heading: "Cough",
  },
  {
    img: Vomiting,
    heading: "Vomiting",
  },
  {
    img: Diarrhea,
    heading: "Diarrhea",
  },
  {
    img: Reflux,
    heading: "Reflux (GORD)",
  },
  {
    img: HeartBurn,
    heading: "Heart Burn",
  },
  {
    img: MentalHealth,
    heading: "Mental Health Discussion",
  },
  {
    img: SkinProblem,
    heading: "Skin Problems",
  },
  {
    img: Asthma,
    heading: "Asthma",
  },
  {
    img: Bronchitis,
    heading: "Bronchitis",
  },
];

export default diseaseData;
