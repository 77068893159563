import React from "react";
import "./Footer.scss";
import { ReactSVG } from "react-svg";
import { facebook, footerLogo, twitter } from "../../Assets";
import { useNavigate } from "react-router-dom";

const Footer = ({ setIsNav }) => {
  const navigate = useNavigate();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-container" onClick={() => setIsNav(false)}>
      <div className="footer-content">
        <div className="logo-description">
          <div className="logo">
            <ReactSVG src={footerLogo} />
          </div>
          <p className="description">
            Welcome to Medidoc, your trusted online destination for accessible
            and affordable healthcare solutions. We are dedicated to providing
            you with easy access to top-notch medical professionals, expert
            advice, and convenient booking options. Whether you need a doctor's
            consultation, second opinion, or health information, our platform is
            designed to prioritize your well-being. Experience healthcare made
            simple and affordable with us.
          </p>
        </div>
        <div className="listed-social">
          <div className="listed-items">
            <h3>Useful Links</h3>
            <ul>
              <li>Careers</li>
              <li>About us</li>
              <li>Contact us</li>
              <li onClick={()=>navigate("/terms")}>Terms of Service</li>
            </ul>
          </div>
          <div className="social-icons">
            <h3>Resources</h3>
            <div className="icons">
              <div className="icon">
                <ReactSVG src={facebook} />
              </div>
              <div className="icon">
                <ReactSVG src={twitter} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="rights">Copyright © Medidoc {year}.</p>
    </div>
  );
};

export default Footer;
